import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { formatOutput } from '../../lib/formatOutput'

export const copy = createAsyncThunk('generator/copy', (_, thunkAPI) => {
  const output = (thunkAPI.getState() as RootState).generator.output

  if (output) {
    navigator.clipboard.writeText(formatOutput(output.json))
  }
})
