import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { generate, GeneratedOutput } from '../thunks/generate'

type GeneratorType = {
  headers: {
    source: string
    destination: string
  },
  output?: GeneratedOutput
}

export const initialState: GeneratorType = {
  headers: {
    source: '',
    destination: '',
  }
}

const { actions, reducer } = createSlice({
  name: 'generator',
  initialState,
  reducers: {
    setHeader: (state, action: PayloadAction<{ header: 'source' | 'destination', value: string }>) => {
      state.headers[action.payload.header] = action.payload.value
    },
  },
  extraReducers: builder => {
    builder.addCase(generate.fulfilled, (state, action) => {
      state.output = action.payload
    })
  }
})

export const generator = reducer

export const { setHeader } = actions