import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type DataType = {
  loaded: boolean
  headers: string[]
  data: string[][]
}

export const initialState: DataType = {
  loaded: false,
  headers: [],
  data: [],
}

const { actions, reducer } = createSlice({
  name: 'data',
  initialState,
  reducers: {
    load: (state, action: PayloadAction<string[][]>) => {
      const headers = action.payload.shift()

      if (headers) {
        state.headers = headers
        state.data = action.payload
        state.loaded = true
      }
    }
  },
})

export const data = reducer

export const { load } = actions